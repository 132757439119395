import { Link } from 'react-router-dom';
import ReplaButton from '../buttons';
import Slider from "react-slick";
import { Typography, Image, Space, } from 'antd';
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons"
import { dataAnalytics, ai, blockchain, mobileAppDevelopment, customSoftware, cyberSecurity } from './data';
const { Title } = Typography
const BlogsCategories = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            }
        ]
    }
    return (
        <>
            <div className="pt-5" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <div style={{ width: '93%' }}>
                        <Title level={2} className='mb-5 align-self-start'>Cyber Security</Title>
                        <Slider {...settings}>
                            {
                                cyberSecurity?.map((cyberSecurity, n) => {
                                    return (
                                        <Link to={cyberSecurity.route} className='px-2' key={'cyberSecurity-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + cyberSecurity?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                cyberSecurity?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {cyberSecurity.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            cyberSecurity?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='d-flex justify-content-center w-100'>
                    <Link to={"/blogs/cyber-security"} style={{ width: '300px' }}>
                        <ReplaButton title='See More' />
                    </Link>
                </div>
            </div>
            <div className="" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <div style={{ width: '93%' }}>
                        <Title level={2} className='mb-5 align-self-start'>Custom Software Solutions</Title>
                        <Slider {...settings}>
                            {
                                customSoftware?.map((customSoftware, n) => {
                                    return (
                                        <Link to={customSoftware.route} className='px-2' key={'customSoftware-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + customSoftware?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                customSoftware?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {customSoftware.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            customSoftware?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='d-flex justify-content-center w-100'>
                    <Link to={"/blogs/custom-software-solutions"} style={{ width: '300px' }}>
                        <ReplaButton title='See More' />
                    </Link>
                </div>
            </div>
            <div className="" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <div style={{ width: '93%' }}>
                        <Title level={2} className='mb-5 align-self-start'>Mobile App Development</Title>
                        <Slider {...settings}>
                            {
                                mobileAppDevelopment?.map((mobileAppDevelopment, n) => {
                                    return (
                                        <Link to={mobileAppDevelopment.route} className='px-2' key={'mobileAppDevelopment-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + mobileAppDevelopment?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                mobileAppDevelopment?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {mobileAppDevelopment.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            mobileAppDevelopment?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='d-flex justify-content-center w-100'>
                    <Link to={"/blogs/mobile-app-development"} style={{ width: '300px' }}>
                        <ReplaButton title='See More' />
                    </Link>
                </div>
            </div>
            <div className="" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <div style={{ width: '93%' }}>
                        <Title level={2} className='mb-5 align-self-start'>Data Analytics</Title>
                        <Slider {...settings}>
                            {
                                dataAnalytics?.map((dataAnalytics, n) => {
                                    return (
                                        <Link to={dataAnalytics.route} className='px-2' key={'dataAnalytics-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + dataAnalytics?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                dataAnalytics?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {dataAnalytics.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            dataAnalytics?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='pt-5 d-flex justify-content-center w-100'>
                    <Link to={"/blogs/data-analytics"} style={{ width: '300px' }}>
                        <ReplaButton title='See More' />
                    </Link>
                </div>
            </div>
            <div className="" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <div style={{ width: '93%' }}>
                        <Title level={2} className='mb-5 align-self-start'>Artificial Intelligence</Title>
                        <Slider {...settings}>
                            {
                                ai?.map((ai, n) => {
                                    return (
                                        <Link to={ai.route} className='px-2' key={'ai-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + ai?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                ai?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {ai.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            ai?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='pt-5 d-flex justify-content-center w-100'>
                    <Link to={"/blogs/artificial-intelligence"} style={{ width: '300px' }} >
                        <ReplaButton title='See More' />
                    </Link>
                </div>
            </div>
            <div className="pb-5" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <div style={{ width: '93%' }}>
                        <Title level={2} className='mb-5 align-self-start'>Blockchain</Title>
                        <Slider {...settings}>
                            {
                                blockchain?.map((blockchain, n) => {
                                    return (
                                        <Link to={blockchain.route} className='px-2' key={'blockchain-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + blockchain?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                blockchain?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {blockchain.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            blockchain?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='pt-5 d-flex justify-content-center w-100'>
                    <Link to={"/blogs/blockchain"} style={{ width: '300px' }} >
                        <ReplaButton title='See More' />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default BlogsCategories;
