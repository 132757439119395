import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';

const { Title } = Typography;
const { Panel } = Collapse;

const Blog10 = () => {
    const title = "Mkaits Technologies: Pioneering Innovations in Blockchain Technology";
    const description = "Dive into the evolving world of decentralized applications (Dapps). Discover how they pave the way for a more autonomous and secure digital future in various industries.";
    const image = "https://www.mkaits.com/assets/blog10cover.jpg"; // Make sure this is a valid URL to an image optimized for social sharing
    const url = "https://www.mkaits.com/blogs/blockchain/pioneering-innovations-in-blockchain-technology";
    // Set up scroll behavior on component mount and clean up on unmount
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Mkaits Technology" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName='Pioneering Innovations in Blockchain Technology' breadcrumb={[{ to: '/blogs', name: 'Blogs' }]} />
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <div className=''>
                    <ShareBlog url={"/blogs/blockchain/pioneering-innovations-in-blockchain-technology"} />
                </div>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                Introduction
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                NFT Gaming Stars: BEP-20 Token Creation
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                NFT Gaming Market: A Decentralized Exchange with P2E Features
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Randomz: A Token-Based Social Media Platform
                            </Link>
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    Introduction
                                </Link>
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    NFT Gaming Stars: BEP-20 Token Creation
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    The NFT Gaming Market: A Decentralized Exchange with P2E Features
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Randomz: A Token-Based Social Media Platform
                                </Link>
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Element name="section1" className="element">
                            <Title className='text-black mb-1 fs-1'>
                                Mkaits Technologies: Pioneering Innovations in Blockchain Technology
                            </Title>
                            <br />
                            <p>At Mkaits Technologies, we pride ourselves on being at the forefront of technological advancement, particularly in the realms of blockchain and gaming. Our efforts have culminated in the development of several innovative projects, showcasing our commitment to enhancing user experiences and promoting engagement within digital ecosystems. Below is a summary of our recent endeavors.</p>
                            <br />
                        </Element>
                        <Element name="section2" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                1. NFT Gaming Stars: BEP-20 Token Creation
                            </Title>
                            <br />
                            <p>Our first notable project is found at <a href="http://www.nftgamingstars.com." target="_blank" rel="noopener noreferrer">www.nftgamingstars.com.</a>  Here, we developed a BEP-20 token on the Binance Smart Chain, alongside a user-centric website that caters to the needs of our users. To ensure broad accessibility, we successfully launched this token on both PancakeSwap and Uniswap. Furthermore, we achieved its listing on CoinMarketCap, enhancing the token's visibility in the bustling cryptocurrency market. The frontend was constructed using TypeScript, which contributed to a smooth browsing experience, allowing users to interact effortlessly with our platform.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/blog10a.jpg" alt="Blockchain" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section3" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                2. NFT Gaming Market: A Decentralized Exchange with P2E Features
                            </Title>
                            <br />
                            <p>Another significant achievement can be seen at <a href="http://www.nftgamingmarket.com." target="_blank" rel="noopener noreferrer">www.nftgamingmarket.com.</a> We built a decentralized exchange (DEX) that integrates play-to-earn (P2E) gaming options along with staking features. This platform is designed to be a hub for gamers and investors alike, merging the excitement of gaming with the advantages of blockchain technology. The combination of these features allows users not only to trade easily but also to earn rewards through their interactions on the platform.</p>
                        </Element>
                        <br />
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/blog10b.jpg" alt="Blockchain" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section4" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                3. Randomz: A Token-Based Social Media Platform
                            </Title>
                            <p>At <a href="http://www.randomz.io" target="_blank" rel="noopener noreferrer">www.randomz.io</a> we have taken a novel approach by launching the Random token, heralded as the first token-based social media platform. This initiative aims to incentivize user engagement by rewarding individuals with tokens for watching and utilizing videos. Our team also developed multiple tokens to support this unique ecosystem, encouraging users to actively participate in content creation and consumption. Through this project, we aim to foster a vibrant community where contributions are recognized and rewarded.</p>
                            <br />
                            <p>In summary, Mkaits Technologies has established itself as a significant player in the intersection of technology and user experience. Our projects reflect our dedication to innovation and our efforts to create platforms that not only meet but exceed user expectations. We look forward to continuing our journey of connecting technology with everyday experiences, empowering users in the digital age.</p>
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/blog10c.jpg" alt="Blockchain" className='img-fluid w-100' preview={false} />
                        </div>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/blockchain/pioneering-innovations-in-blockchain-technology"} />
                </div>
            </div>
        </>
    );
}

export default Blog10;
