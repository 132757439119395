import React from 'react'
import { SectionTop } from '../../ui-elements';
import { Typography, Image, Space, Row, Col, Pagination } from 'antd';
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
import './index.css'

const BlogBlock = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
      };

    const news = [
        {
            title: 'Mkaits Technologies: Pioneering Innovations in Blockchain Technology',
            date: '22 December 2024',
            url: 'blog10cover.jpg',
            type: 'Blockchain',
            route: 'pioneering-innovations-in-blockchain-technology'
        },
        {
            title: 'Exploring the World of Dapps: The Future of Decentralized Applications',
            date: '13 July 2024',
            url: 'blog9cover.jpg',
            type: 'Blockchain',
            route: 'exploring-the-world-of-dapps-the-future-of-decentralized-applications'
        },
        {
            title: 'DeFi: Reshaping the Financial Landscape',
            date: '09 July 2024',
            url: 'blog8cover.jpg',
            type: 'Blockchain',
            route: 'defi-reshaping-the-financial-landscape'
        },
        {
            title: 'The Transformative Potential of Decentralized Exchanges (DEX) ',
            date: '30 June 2024',
            url: 'blog7cover.jpg',
            type: 'Blockchain',
            route: 'transformative-potential-of-decentralized-exchanges'
        },
        {
            title: 'Decentralized Autonomous Organization (DAO)',
            date: '26 June 2024',
            url: 'blog6cover.jpg',
            type: 'Blockchain',
            route: 'decentralized-autonomous-organization'
        },
        {
            title: 'Smart Contracts',
            date: '23 June 2024',
            url: 'blog5cover.jpg',
            type: 'Blockchain',
            route: 'smart-contracts'
        },
        {
            title: 'Double the Fun with 2-Layer Blockchain: A Revolutionary Approach to Decentralization',
            date: '21 June 2024',
            url: 'blog4cover.png',
            type: 'Blockchain',
            route: 'double-the-fun-with-2-layer-blockchain-a-revolutionary-approach-to-decentralization'
        },
        {
            title: 'One Layer Wonder: The Marvels of 1-Layer Blockchain',
            date: '19 June 2024',
            url: 'blog3cover.png',
            type: 'Blockchain',
            route: 'one-layer-wonder-the-marvels-of-1-layer-blockchain'
        },
        {
            title: 'Blockchain Unleashed: A New Era of Innovation',
            date: '30 May 2024',
            url: 'blog2cover.png',
            type: 'Blockchain',
            route: 'blockchain-unleashed-a-new-era-of-innovation'
        },
        {
            title: 'Blockchain: The Unsung Hero Beyond Cryptocurrencies',
            date: '23 May 2024',
            url: 'blog1cover.png',
            type: 'Blockchain',
            route: "blockchain-the-unsung-hero-beyond-cryptocurrencies"
        },
    ]
    return (
        <div>
            <div className="py-5" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    {/* <SectionTop title='' description='News & Updates'/> */}
                    <div style={{ width: '93%' }}>
                        <Row gutter={[32, 32]}>
                            {
                                news?.map((news, n) => {
                                    return (
                                        <Col xs={24} sm={12} md={8}>
                                            <div className='px-2' key={'news-' + n}>
                                                <Link to={news?.route} className=" rounded-1 news-slider" onClick={scrollToTop}>
                                                    <div className='news-slider-image-box'>
                                                        <Image src={'/assets/' + news?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                        <div className='two animation'>
                                                            <Image src={'/assets/plus.png'} alt='olive' />
                                                        </div>
                                                        <span className='tag px-3 py-1'>
                                                            <Typography.Text strong className='text-white'>
                                                                {
                                                                    news?.type
                                                                }
                                                            </Typography.Text>
                                                        </span>
                                                    </div>
                                                    <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                        <Space direction='horizontal' size={10}>
                                                            <Space>
                                                                <ClockCircleOutlined
                                                                    style={{ transform: 'translateY(-4px)' }}
                                                                    className='brand-color'
                                                                />
                                                                <Typography.Text className='my-0' strong>{news?.date}</Typography.Text>
                                                            </Space>
                                                            <Space>
                                                                <UserOutlined
                                                                    style={{ transform: 'translateY(-4px)' }}
                                                                    className='brand-color'
                                                                />
                                                                <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                            </Space>
                                                        </Space>
                                                        <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                            {
                                                                news?.title
                                                            }
                                                        </Typography.Title>
                                                    </Space>
                                                </Link>
                                            </div>
                                        </Col>

                                    )
                                })
                            }
                        </Row>

                        {/* <Row gutter={[32, 32]} className='text-center mt-5'>
                            <Col span={24}>
                                <Space direction='vertical' className='pagin'>
                                    <Pagination defaultCurrent={1} total={50} />
                                </Space>
                            </Col>
                        </Row> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogBlock